<template>
  <div class="goods-detail">
    <!-- 轮播图 -->
    <banner
      :banners="banners"
      v-model="detail.is_collect"
      style="z-index:0"
    ></banner>
    <flash-sale
      :goodsType="goodsType"
      :detail="detail"
      :goodsPrice="goodsPrice"
      :businessType="businessType"
      v-if="goodsType == 1 || goodsType == 2"
    ></flash-sale>
    <div class="goods-info">
      <div class="goods-intro">
        <p class="goods-name ft-weight">{{ detail.item_name }}</p>
        <p class="goods-tip">{{ detail.item_desc }}</p>
        <div v-if="goodsType == 0 || goodsType == 3" class="goods-price">
          <div class="group-title" v-if="goodsType == 3">拼团价：</div>
          <template v-if="businessType == 2">
            <p class="real-price" v-if="price_status == 1">
              <span>
                {{
                  priceShow(
                    detail.display_type,
                    detail.price,
                    detail.max_integral_percentage
                  )
                }}
              </span>
            </p>
            <s
              v-if="
                goodsPrice.del_price &&
                  mall_price_status == 1 &&
                  detail.display_type !== 2
              "
              class="s-price"
            >
              {{ priceShow(detail.display_type, goodsPrice.del_price) }}
            </s>
            <span
              v-if="
                tripartite_type === 'VUE_APP_ZJG' && detail.display_type !== 1
              "
              class="zjg-max"
            >
              积分最高抵扣{{ detail.max_integral_percentage }}%
            </span>
          </template>
          <template v-else>
            <p class="real-price" v-if="price_status == 1">
              <span>¥</span>
              <span>{{ goodsPrice.real_price }}</span>
            </p>
            <s
              v-if="goodsPrice.del_price && mall_price_status == 1"
              class="s-price"
              >¥{{ goodsPrice.del_price }}</s
            >
          </template>
        </div>
      </div>
      <div class="goods-serve">
        <div
          class="serve-item"
          v-for="(item, index) in serveItems"
          :key="index"
        >
          <img :src="item.img" alt />
          <span>{{ item.content }}</span>
        </div>
      </div>
      <div class="goods-buy">
        <div v-if="itemsnStatus">
          <div v-if="detail.display_type === 3" class="buy-item stage">
            <p class="buy-title">支付方式</p>
            <p class="buy-content" @click="showStage">
              <span class="plans money">{{ avg_price }}</span>
              <span class="plans">元/</span>
              <span class="gary">月起&nbsp;</span>
              {{ stage_num }}期
              <img src="~@/assets/ic_arrow_right_gray@2x.png" alt />
            </p>
          </div>
          <div
            v-if="getVirtualStatus(detail.source_type)"
            class="buy-item"
            @click="chooseAddress()"
          >
            <p class="buy-title">送至</p>
            <p class="buy-content">
              <span v-if="activedAddress.area_string">
                {{ activedAddress.area_string }}
              </span>
              <span v-else>{{ default_address.area_string }}</span>
              <img src="~@/assets/ic_arrow_right_gray@2x.png" alt />
            </p>
          </div>
          <div v-if="detail.active_name" class="buy-item">
            <p class="buy-title">活动</p>
            <p class="buy-content">
              <span class="active-tip">{{ detail.active_name }}</span>
            </p>
          </div>
          <div
            v-if="detail.is_own == 0 && getVirtualStatus(detail.source_type)"
            class="buy-item"
          >
            <p class="buy-title">库存</p>
            <p class="buy-content">
              <span v-if="goodsInfo.stock_name == '有货'" class="have-goods">
                {{ goodsInfo.stock_name }}
              </span>
              <span v-else class="no-goods">{{ goodsInfo.stock_name }}</span>
            </p>
          </div>
          <div v-if="detail.source_type" class="buy-item">
            <p class="buy-title">来源</p>
            <p class="buy-content">
              {{ getSourceTypes(detail.source_type) }}
            </p>
          </div>
          <div v-if="salesStatus()" class="buy-item">
            <p class="buy-title">已售</p>
            <p class="buy-content">
              {{
                getSales(detail.id, detail.cost_price)
                  ? getSales(detail.id, detail.cost_price)
                  : ""
              }}
            </p>
          </div>
          <div
            v-if="
              detail.source_type != 2 &&
                detail.source_type != 3 &&
                getVirtualStatus(detail.source_type) &&
                !customAccount('VUE_APP_CHAOWEI')
            "
            class="buy-item"
          >
            <p class="buy-title">运费</p>
            <p class="buy-content">{{ goodsInfo.postage }}</p>
          </div>
        </div>
        <div v-else>
          <div
            class="buy-item"
            style="border-bottom: 1px solid #e1e1e1;padding-bottom: 0.2rem;margin-bottom: 0.2rem;"
          >
            <p class="buy-title">SKU码</p>
            <p class="buy-content">{{ detail.item_sn }}</p>
          </div>
        </div>

        <div
          v-if="
            basic_fee > 0 &&
              detail.source_type != 2 &&
              detail.source_type != 3 &&
              getVirtualStatus(detail.source_type)
          "
          class="buy-item"
        >
          <p class="buy-title">基础运费</p>
          <template v-if="businessType == 2">
            <p class="buy-content">{{ priceShow(1, basic_fee) }}</p>
          </template>
          <template v-else>
            <p class="buy-content">¥{{ basic_fee }}</p>
          </template>
        </div>
        <!-- 拼团玩法 -->
        <group-play v-if="goodsType == 3"></group-play>
      </div>
    </div>
    <!-- 好友拼团列表 -->
    <div v-if="goodsType == 3 && groupList.length > 0" class="group-content">
      <group-list
        :moreGroup="true"
        :groupList="groupList"
        :group_buy_item_id="detail.group_buy_item_info.id"
      ></group-list>
    </div>
    <!-- 评价列表 -->
    <!-- <div class="evaluate-content" v-if="evaluateTotal > 0">
      <div class="evaluate-title" @click="moreEvaluate()">
        <div class="tip">商品评价（{{ evaluateTotal }}）</div>
        <div class="look-more">
          <span>查看全部</span>
          <img src="~@/assets/icon_more_light@2x.png" alt />
        </div>
      </div>
      <evaluate-list :list="evaluateList"></evaluate-list>
    </div> -->
    <!-- 商品详情 富文本 -->
    <div class="goods-html">
      <div class="content-title">
        <span class="dian"></span>
        <span class="line"></span>
        <span class="text">商品详情</span>
        <span class="line"></span>
        <span class="dian"></span>
      </div>
    </div>

    <div
      v-if="imgLists.length > 0"
      key="img-lists"
      :class="[isnew ? 'jd' : 'contents']"
      v-lazy-container="lazyloadConf"
    >
      <img v-for="(item, index) in imgLists" :data-src="item" :key="index" />
    </div>
    <div
      v-else
      key="img-html"
      :class="[isnew ? 'jd' : 'contents']"
      :style="{ transform: isnew ? `scale(${scale})` : `scale(1)` }"
      v-html="item_txt.contents"
    ></div>

    <div
      v-if="itemsnStatus && !pointsTopic && show_only !== 1"
      class="goods-bot"
    >
      <div class="bot-icon" @click.stop="openChat" v-if="ServiceStatus == 1">
        <img src="~@/assets/icon_shangpin_kefu@2x.png" alt />
        <p>客服</p>
      </div>
      <div v-if="ServiceStatus == 0" class="bot-icon" @click.stop="openChat">
        <img src="~@/assets/icon_my_kefu@3x.png" alt />
        <p>客服</p>
      </div>

      <div v-if="sermob == true && ServiceStatus == 0" class="PopUp">
        <div class="bot-mobile" @click="openChat">
          <img src="~@/assets/ic_back_.png" @click.stop="cancel" />
          <span class="service-phone">
            客服电话:
            <span>{{ ServiceMobile }}</span>
          </span>
          <span class="dial">
            <a :href="'tel:' + ServiceMobile">确认呼叫</a>
          </span>
          <span class="cancel" @click.stop="cancel">取消</span>
        </div>
      </div>
      <div class="bot-icon" @click="shoppingCar()">
        <img src="~@/assets/icon_shangpin_gouwuche@2x.png" alt />
        <p>购物车</p>
      </div>
        <div v-if="is_collect == 0" class="bot-icon" @click="collectGoods()">
          <img src="~@/assets/ic_shoucang.png" alt />
          <p>收藏</p>
        </div>
        <div v-else class="bot-icon" @click="collectGoods()">
          <img src="~@/assets/ic_qxsc.png" alt />
          <p>取消收藏</p>
        </div>
      <div
        v-if="goodsInfo.stock_name === '无货' && goodsType !== 1"
        class="no-goods"
      >
        该地区暂时无货
      </div>
      <div v-else-if="detail.stock == 0 && goodsType !== 1" class="no-goods">
        {{ goodsType }}该地区暂时无货
      </div>
      <div v-else-if="detail.isuse !== 1" class="no-goods">
        该商品已下架
      </div>
      <div v-else-if="goodsType == 0" class="buy-btn">
        <div v-if="carStatus" class="add-car" @click="showDialog(0)">
          加入购物车
        </div>
        <div
          v-else
          class="add-car"
          style="opacity: 0.6;background: #ccc;color:#fff"
        >
          加入购物车
        </div>
        <div class="buy-now" @click="showDialog(1)">
          立即{{
            tripartite("VUE_APP_TABEN") || customAccount("VUE_APP_CHAOWEI")
              ? "兑换"
              : "购买"
          }}
        </div>
      </div>
      <div v-else-if="goodsType == 3" class="buy-btn">
        <div class="self-buy" @click="showDialog(2)">
          <template v-if="mall_price_status == 1">
            <template v-if="businessType == 2">
              <p>
                {{ priceShow(detail.display_type, goodsPrice.del_price) }}
              </p>
              <p>单独购买</p>
            </template>
            <template v-else>
              <p>¥ {{ goodsPrice.del_price }}</p>
              <p>单独购买</p>
            </template>
          </template>
        </div>
        <div class="group-buy" @click="showDialog(3)">
          <template v-if="price_status == 1">
            <template v-if="businessType == 2">
              <p>
                {{ priceShow(detail.display_type, goodsPrice.real_price) }}
              </p>
              <p>{{ detail.group_buy_item_info.people_limit }}人开团</p>
            </template>
            <template v-else>
              <p>¥ {{ goodsPrice.real_price }}</p>
              <p>{{ detail.group_buy_item_info.people_limit }}人开团</p>
            </template>
          </template>
        </div>
      </div>
      <div v-else-if="goodsType == 1" class="buy-btn sale-kill-btn">
        <div v-if="detail.limit_item_info.stock === 0" class="sale-none">
          已抢光
        </div>
        <div v-else class="buy-now" @click="showKillDialog(1)">
          立即{{
            tripartite("VUE_APP_TABEN") || customAccount("VUE_APP_CHAOWEI")
              ? "兑换"
              : "购买"
          }}
        </div>
      </div>
      <div v-else-if="goodsType === 2" class="buy-btn sale-kill-btn">
        <div class="sale-none">秒杀未开始</div>
      </div>
    </div>
    <div
      v-if="pointsTopic"
      class="goods-bot"
      :class="{ 'point-topic': pointsTopic }"
      @click="showDialog(1)"
    >
      立即兑换
    </div>
    <select-address
      ref="address"
      :edit_date="edit_date"
      @confirmAddress="confirmAddress"
    ></select-address>

    <sku
      v-if="loadding"
      ref="sku"
      v-model="dialog"
      :buyType="buyType"
      :skuList="skuList"
      :skuInfo="skuInfo"
      :goodsType="goodsType"
      :goodsDetail="detail"
      :limitNum="limitNum"
    ></sku>

    <stage
      v-if="loadding"
      :status="stageDialog"
      :list="stageList"
      @closeStage="showStage"
    ></stage>
  </div>
</template>

<script>
import banner from "@/components/goods/banner";
import evaluateList from "@/components/goods/evaluateList";
import flashSale from "@/components/goods/flashSale";
import groupList from "@/components/goods/groupList";
import groupPlay from "@/components/goods/groupPlay";
import sku from "@/components/goods/sku";
import stage from "@/components/goods/stage";
import selectAddress from "@/components/common/selectAddress";
import {
  goodsDetail,
  getGroupList,
  goodsEvaluate,
  checkGoods,
  collectGoods,
  getLimitBuyNum
} from "@/services/goodsApi.js";
import {
  getGoodsType,
  getGoodsPrice,
  getBanners,
  SERVE_ITEMS,
  GROUP_RULES,
  getSales,
  priceShow
} from "@/utils/goodsUtil.js";
import "@/utils/qiyu";
import "@/static/js/wxapi"; //微信
import { baseUrl } from "../../utils/env";
import { getStore, wxShare } from "../../utils/common";
import { mapState } from "vuex";
import { ServicePhone, getShareText, userInfoViaGeneralize } from "@/services";
import { tripartite, customAccount, zjgKeepAlive } from "@/utils/custom";
import { orderMixin } from "@/mixins/orderMixin.js";
const loadingImg = require('@/static/image/loading_750x320.gif')

export default {
  name: "goodsDetail",
  mixins: [orderMixin],
  data() {
    return {
      item_id: this.$route.query.item_id,
      loadding: false, // 是否加载完成
      detail: {}, // 商品详情
      item_txt: "", //商品详情富文本
      imgLists: [], // 富文本图片地址
      goodsPrice: {}, // 商品相关价格
      buyType: 0, // 购买类型， 0：左边按钮，1：右边按钮 eg: 0，加入购物车， 1 立即购买
      banners: [],
      serveItems: SERVE_ITEMS,
      show: false,
      groupRules: GROUP_RULES,
      dialog: false,
      goodsType: 0, // 商品类型判断  0： 普通商品，  1： 限时购商品在售状态   2： 限时购商品预售状态   3： 团购商品
      skuList: [], // 所有的sku属性
      skuInfo: [], // 所有sku商品
      groupList: [], // 团购商品的开团成员列表
      activedAddress: {}, //选择地址
      evaluateList: [], // 评价列表
      evaluateTotal: 0,
      edit_date: "", //默认显示的收货地址
      max_integral: "", //积分使用比例
      is_collect: 0, // 是否收藏
      ServiceMobile: "",
      ServiceStatus: "",
      sermob: false, //弹框
      share_desc: "", //分享描述
      basic_fee: 0, //基础运费
      itemsnStatus: true, // 商品编号状态（商品详情页是否显示购买功能）
      scale: "", //缩放比例
      isnew: false, //京东返回详情html结构是否为新
      carStatus: true, // 是否能添加购物车
      item_active_type: this.$route.query.item_active_type, //  秒杀传参
      limitNum: Number,
      stageList: [],
      avg_price: "", // 分期金额
      stage_num: "", // 分期期数
      stageDialog: false,
      pointsTopic: false, //  定制积分专题
      activity_id: 0, // 中行蒙牛点赞活动id
      lazyloadConf: {
        selector: "img",
        loading: loadingImg,
      },
    };
  },
  computed: {
    goodsInfo() {
      return this.$store.state.goodsInfo;
    },
    /* maxInt() {
      if (this.goodsPrice.real_price) {
        let theInt = Math.ceil(
          (this.goodsPrice.real_price - this.cash) * this.integralRate
        );
        return theInt;
      }
    },
    cash() {
      if (this.goodsPrice.real_price) {
        let moneyRatio = 1 - this.max_integral,
          thePrice = this.goodsPrice.real_price * moneyRatio,
          theMoney =
            getPointNum(thePrice) === 0
              ? thePrice * 100
              : Math.ceil(thePrice * 100);
        return (theMoney / 100).toFixed(2);
      }
    }, */
    ...mapState({
      bid: state => state.businessInfo.id, //商城id
      show_only: state => state.businessInfo.mall_display_only, // 商城展示状态
      default_address: state => state.default_address, //默认地址信息
      businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      price_status: state => state.config.price_status, // 是否展示价格 1展示 0 不展示
      mall_price_status: state => state.config.mall_price_status, //  是否展示价格 1展示 0 不展示
      // integralRate: state => state.config.integral_rate // 积分比例
      businessType: state => state.businessInfo.type, //1现金商城，2积分商品，3内采商城
      tripartite_type: state => state.tripartite_type // 三方商城类型
    })
  },
  components: {
    banner,
    evaluateList,
    flashSale,
    groupList,
    groupPlay,
    sku,
    stage,
    selectAddress
  },
  //路由改变jssdk参数重新赋值
  // beforeRouteEnter(to, from, next) {
  //   next();
  //   wxShare();
  // },
  methods: {
    // 积分3.0显示
    priceShow,

    // 定制商城判断
    tripartite,
    customAccount,
    //获取分享描述
    async getShare() {
      const share = await getShareText();
      this.share_desc = share.data.share_text || "";
    },
    chooseAddress() {
      zjgKeepAlive();
      this.$refs.address.choseAdd();
    },
    confirmAddress(params) {
      this.activedAddress = params;
      this.$store.commit("setAddress", params);
      this.checkGood(params);
    },
    // 商品详情选择地址判断邮费（第三方需要判断是否有货）
    async checkGood(params) {
      try {
        let address_info = params;
        let data = {
          item_id: this.item_id,
          province_id: address_info.province_id,
          city_id: address_info.city_id,
          area_id: address_info.area_id,
          town_id: address_info.street_id
        };
        let checkData = await checkGoods(data);
        this.basic_fee = checkData.data.basic_fee || 0;
        if (checkData.code === 0) {
          this.$store.commit("setGoodsInfo", {
            postage: checkData.data.postage,
            stock_name: checkData.data.stock_name
          });
        } else {
          this.$dialog.toast({
            mes: checkData.msg,
            timeout: 1500,
            icon: "none",
            callback: () => {
              // this.closeDialog();
            }
          });
        }
      } catch (err) {
        throw err;
      }
    },
    shoppingCar() {
      this.$router.push({
        path: "/shopping_cart"
      });
    },
    //收藏
    async collectGoods() {
      if(this.activity_id!==0){
       this.$dialog.toast({
          mes: "活动商品暂不支持收藏！",
          timeout: 1000
        })
        return false
      }
      zjgKeepAlive();
      
      try {
        let data = await collectGoods(this.$route.query.item_id);
        if (data.code === 0) {
          this.is_collect = this.is_collect == 1 ? 0 : 1;
        }
      } catch (err) {
        throw err;
      }
    },

    // 显示分期
    showStage() {
      this.stageDialog = !this.stageDialog;
    },

    showDialog(type) {
      this.$refs.sku.initSku();
      zjgKeepAlive();

      if (
        !this.$store.state.activedAddress.province_id &&
        this.detail.source_type != 6
      ) {
        this.chooseAddress();
        this.$dialog.toast({
          mes: "请先选择配送区域",
          timeout: 1000
        });
      } else {
        this.buyType = type;
        this.dialog = true;
      }
    },

    // 秒杀判断是否限购
    async showKillDialog(type) {
      this.$refs.sku.initSku();
      try {
        console.log("detail", this.detail);
        let limit_buy_id = this.detail.limit_buy_info.id || "";
        let limit_buy_item_id = this.detail.limit_item_info.id || "";
        let data = await getLimitBuyNum(limit_buy_id, limit_buy_item_id);
        this.limitNum = data.data;
        if (data.data > 0) {
          if (
            !this.$store.state.activedAddress.province_id &&
            this.detail.source_type != 6
          ) {
            this.chooseAddress();
            this.$dialog.toast({
              mes: "请先选择配送区域",
              timeout: 1000
            });
          } else {
            this.buyType = type;
            this.dialog = true;
          }
        } else {
          this.$dialog.toast({
            mes: data.msg || "超过限购数量"
          });
        }
      } catch (err) {}
    },
    // 获取团购商品的开团用户列表
    async getGroupList() {
      try {
        let group_buy_item_id = this.detail.group_buy_item_info.id;
        let data = await getGroupList(group_buy_item_id, 0, 3);
        if (data.code === 0) {
          this.groupList = data.data.data;
        } else {
          this.errDialog(data);
        }
      } catch (err) {
        throw err;
      }
    },
    // 进行商品详情的相关赋值
    initGoodsDetail(data) {
      this.item_txt = data.item_txt; //商品详情富文本
      /* if(this.item_txt.contents.indexOf("zbViewModulesH")>-1||this.item_txt.contents.indexOf("ke-zeroborder")>-1){
        this.isnew=true  //判断京东返回接口是否包含 "zbViewModulesH" class类名  有为新
      } */

      // 处理富文本图片，http为https
      const srcArr = this.item_txt.contents.match(/src="([^"]*)"/g);
      // console.log(srcArr);
      if (srcArr) {
        let newArr = [];
        srcArr.forEach(item => {
          let s = item.split('src="')[1];
          newArr.push(s.substring(0, s.length - 1));
        });
        // console.log(newArr);
        this.imgLists = newArr.map(item => {
          const head = item.substr(0, 5);
          if (head === "https") {
            return item;
          } else {
            if (item.substr(0, 4) === "http") {
              const newUrl = `https${item.substring(4)}`;
              return newUrl;
            } else {
              return `https:${item}`;
            }
          }
        });
      }

      if (data.source_type == 4 || data.source_type == 5) {
        this.isnew = true;
      }
      // console.log(data.source_type)
      this.is_collect = data.is_collect; //是否收藏
      this.max_integral = Number.parseFloat(data.max_integral) / 100;
      this.goodsType = getGoodsType(data);
      // 处理在有拼团情况下，非拼团详情页点击进来的出现拼团问题
      if (!this.group_buy_id && !this.item_active_type) {
        this.goodsType = 0;
      }
      this.goodsPrice = getGoodsPrice(data, this.goodsType);
      this.detail = { ...data, ...this.goodsPrice };
      this.banners = getBanners(data.photo_list);

      if (this.goodsType === 3) {
        this.getGroupList();
      }
      if (this.detail.has_sku == 1) {
        this.skuList = this.detail.sku_list;
        this.skuInfo = this.detail.sku_info;
        // console.log(this.detail.sku_info);
      } else {
        this.skuInfo.push({
          item_id: this.item_id,
          price: this.goodsPrice.real_price,
          del_price: this.goodsPrice.del_price,
          status: 1,
          stock: this.detail.stock
        });
      }
      this.loadding = true;
    },
    async getGoodsDetail() {
      try {
        let data = await goodsDetail(
          this.item_id,
          this.item_active_type,
          this.activity_id
        );
        if (data.code === 0) {
          this.initGoodsDetail(data.data);
          this.shoppingcarStatus();
          this.stageList = data.data.installment_plans;
          if (this.stageList) {
            this.avg_price = this.stageList.slice(-1)[0].avg_price;
            this.stage_num = this.stageList.slice(-1)[0].num;
          }
        } else {
          this.errDialog(data);
        }
      } catch (err) {
        throw err;
      }
      this.shareFriend();
    },

    // 加入购物车状态
    shoppingcarStatus() {
      const status1 = !this.getVirtualStatus(this.detail.source_type), // 虚拟产品
        status2 =
          this.detail.display_type === 2 &&
          this.bid !== Number(process.env.VUE_APP_KUNSHAN), // 积分+现金类型商品且不是昆山商城
        status3 = this.detail.display_type === 3, // 昆山分期商品
        status4 = this.detail.delivery_type === "pick", // 商品类型线下核销
        status5 = this.activity_id !== 0
      if (status1 || status2 || status3 || status4 || status5) {
        this.carStatus = false;
      }
    },

    //分享
    shareFriend() {
      let self = this;
      let bid = window.localStorage.getItem("bid");
      let auto_url = encodeURIComponent(`goods-detail?item_id=${self.item_id}`);
      let link = encodeURI(
        `${encodeURI(baseUrl)}?auto_url=${auto_url}&display_only=${
          this.show_only
        }&bid=${encodeURI(bid + "#")}/goods-detail?item_id=${self.item_id}`
      );
      wx.config({
        debug: false,
        appId: appId,
        timestamp: timestamp,
        nonceStr: nonceStr,
        signature: signature,
        jsApiList: [
          "checkJsApi",
          // 'onMenuShareTimeline',//旧的接口，即将废弃
          // 'onMenuShareAppMessage',//旧的接口，即将废弃
          "updateAppMessageShareData",
          "updateTimelineShareData",
          "onMenuShareQQ",
          "onMenuShareWeibo",
          "chooseImage",
          "uploadImage",
          "previewImage",
          "openLocation",
          "chooseWXPay"
        ]
      });
      wx.ready(function() {
        let shareData = {
          title: business_name, // 分享标题
          desc:
            self.share_desc ||
            window.localStorage.getItem("share_text") ||
            "我发现了一个很棒的商品，分享给你说不定你也喜欢", // 分享描述
          link: link, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
          imgUrl:
            self.detail.base_pic ||
            "http://yiqitong.oss-cn-hangzhou.aliyuncs.com/Uploads/image/2020-05/20200521150849_%E6%9C%AA%E6%A0%87%E9%A2%98-1.png" // 分享图标
        };
        wx.onMenuShareAppMessage(shareData);
        wx.onMenuShareTimeline(shareData);
        wx.updateAppMessageShareData(shareData);
        wx.updateTimelineShareData(shareData);
        wx.onMenuShareQQ(shareData);
        wx.onMenuShareWeibo(shareData);
      });
    },
    // 报错弹窗
    errDialog(data) {
      this.$dialog.toast({
        mes: data.msg,
        timeout: 1500,
        icon: "none",
        callback: () => {
          // this.$router.go(-1);
        }
      });
    },

    // 客服
    async openchated() {
      try {
        let res = await ServicePhone();
        if (res.code == 0) {
          this.ServiceStatus = res.data.service_status; //开启
          this.ServiceMobile = res.data.service_mobile; //关闭
        }
      } catch (err) {
        throw err;
      }
    },
    //客服聊天
    async openChat() {
      // console.log(this.ServiceStatus);
      //   //判断后台是否开启客服链接
      if (this.ServiceStatus == 1) {
        var isSdkReady = false;
        ysf("onready", function() {
          isSdkReady = true;
        });
        // 判断 点击客服跳转到客服中心
        if (isSdkReady) {
          // location.href = ysf("url");
        }
        let self = this;
        let uid, mobile, name;
        if (localStorage.getItem("userInfo")) {
          uid = JSON.parse(localStorage.getItem("userInfo")).id;
          mobile = JSON.parse(localStorage.getItem("userInfo")).mobile;
          name = JSON.parse(localStorage.getItem("userInfo")).username;
        } else {
          uid = `${Math.random()
            .toString(36)
            .substr(2)
            .slice(0, 4)}${Math.random()
            .toString(36)
            .substr(2)
            .slice(0, 4)}`;
        }
        ysf("config", {
          uid: uid,
          mobile: mobile || "商城",
          name: name || "商城",
          unconfirm: 1,
          success: function() {
            // 成功回调
            ysf("product", {
              show: 1, // 1为打开， 其他参数为隐藏（包括非零元素）
              title: self.detail.item_name || "商城",
              desc: self.detail.item_desc,
              picture: self.detail.base_pic,
              note: business_name,
              url: window.location.href,
              success: function() {
                // 成功回调
                if (isSdkReady) {
                  // ysf("open");
                  location.href = ysf("url");
                }
              },
              error: function() {
                // 错误回调
                // handle error
              }
            });
          },
          error: function() {
            // 错误回调
            // handle error
          }
        });
      } else {
        this.sermob = true;
      }
    },
    cancel() {
      this.sermob = false;
      // console.log(this.sermob);
    },

    // 商品评价
    async goodsEvaluate() {
      try {
        let data = await goodsEvaluate(this.item_id, 1, 3);
        if (data.code === 0) {
          this.evaluateList = data.data.data;
          this.evaluateTotal = data.data.total;
        }
      } catch (err) {
        throw err;
      }
    },
    // 更多评价
    moreEvaluate() {
      this.$router.push({
        path: "/comment_list",
        query: {
          item_id: this.item_id
        }
      });
    },
    //已售显隐
    salesStatus() {
      return this.bid === 1131 || this.bid === 1134;
    },
    //处理已售
    getSales,

    //没有登录或者没有默认地址时显示商城配置地址
    getShopAddress() {
      let address = this.default_address || {};
      // console.log(address);
      if (Object.keys(address).length === 0) {
        let shopAddress = JSON.parse(getStore("businessInfo")),
          addressParams = {
            area_id: shopAddress.area_id,
            area_string: shopAddress.area_string,
            city_id: shopAddress.city_id,
            province_id: shopAddress.province_id,
            street_id: shopAddress.street_id
          };
        this.confirmAddress(addressParams);
      } else {
        this.activedAddress = address;
        this.$store.commit("setAddress", address);
      }
    },

    //判断打开页面是否包含businessInfo信息
    async infoStatus() {
      let inf = getStore("businessInfo");
      if (!inf) {
        await this.$store.dispatch("getBusinessRate");
        inf = getStore("businessInfo");
      }
      if (JSON.parse(inf).hasOwnProperty("show_item_sn")) {
        if (JSON.parse(inf).show_item_sn === 1) {
          this.itemsnStatus = false;
        }
      }
      this.getShopAddress();
    },

    //  积分专题定制
    isPointTopic() {
      if (customAccount("VUE_APP_POINTS_TOPIC")) {
        //是否为海元素商城
        this.pointsTopic = true;
        this.$store.dispatch("getUserInfo"); //用户是非被禁掉
        // console.log('12', this.pointsTopic)
      }
    }
  },
  created() {
    this.activity_id = this.$route.query.activity_id || 0;
    this.infoStatus();

    this.getShare();
    //客服
    sessionStorage.route = this.$route.fullPath;
    this.openchated();
    /* let address = this.default_address || {};
    this.activedAddress = address;
    this.$store.commit("setAddress", address); */
    this.$store.commit("setGoodsInfo", {
      postage: "--",
      stock_name: "--"
    });
    // 获取商品详情
    this.getGoodsDetail();
    // 获取商品评价列表
    this.goodsEvaluate();
    document.title = "商品详情";
    // let stateObject = {};
    // let title = "title";
    // let newUrl = `${baseUrl}/#/goods-detail?item_id=${this.$route.query.item_id}`;
    // history.replaceState(stateObject, title, newUrl);
    this.isPointTopic();
  },
  mounted() {
    if (JSON.stringify(this.default_address) !== "{}" && this.default_address) {
      this.edit_date = this.default_address;
      this.checkGood(this.default_address);
    }
    this.scale =
      getComputedStyle(window.document.documentElement)["font-size"].substring(
        0,
        2
      ) / 100;
  }
};
</script>
<style scoped lang="less">
@import "~@/common/less/variable.less";
@import "~@/common/less/mixin.less";

.goods-detail {
  padding-bottom: 1.1rem;
  box-sizing: border-box;
  background: #fff;
  height: 0;
  // float: left;
  .goods-info {
    background: #fff;
    padding: 0 0.3rem;
    box-sizing: border-box;

    .goods-intro {
      padding: 0.1rem 0 0.2rem;
      box-sizing: border-box;
      border-bottom: 1px solid #e1e1e1;

      .goods-name {
        font-size: 0.36rem;
        color: #333333;
        .ellipsis(4);
        line-height: 0.52rem;
      }

      .goods-tip {
        font-size: 0.28rem;
        color: #999999;
        line-height: 0.4rem;
      }

      .goods-price {
        display: flex;
        align-items: baseline;
        margin-top: 0.14rem;

        .group-title {
          width: 1.12rem;
          height: 0.4rem;
          line-height: 0.4rem;
          color: var(--main-color);
          font-size: 0.28rem;
          white-space: nowrap;
        }

        .real-price {
          color: #333333;
          margin-right: 0.16rem;

          span:nth-child(1) {
            .ft-weight;
            font-size: 0.36rem;
          }

          span:nth-child(2) {
            .ft-weight;
            font-size: 0.5rem;
          }
        }

        .s-price {
          font-size: 0.24rem;
          color: rgba(0, 0, 0, 0.38);
        }

        .zjg-max {
          color: var(--main-color);
          font-size: 0.24rem;
          margin-left: 0.1rem;
        }
      }

      .max-integral {
        margin-top: 0.14rem;
        color: var(--main-color);
      }
    }

    .goods-serve {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 0.7rem;
      border-bottom: 1px solid #e1e1e1;

      .serve-item {
        width: 33%;
        font-size: 0.22rem;
        color: #000;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
          width: 0.24rem;
          height: 0.24rem;
          margin-right: 5px;
        }
      }
    }

    .goods-buy {
      padding-top: 0.26rem;
      box-sizing: border-box;

      .buy-item {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 0.28rem;
        padding-bottom: 0.3rem;
        line-height: 0.4rem;

        .buy-title {
          color: #999999;
        }

        .buy-content {
          color: #333333;
          display: flex;
          align-items: center;
          max-width: 5rem;

          .active-tip {
            display: block;
            color: var(--main-color);
            padding: 0 5px;
            border: 1px solid var(--main-color);
            border-radius: 0.36rem;
            height: 0.36rem;
            display: flex;
            align-items: center;
          }

          .have-goods {
            color: var(--main-color);
          }

          .no-goods {
            color: rgba(153, 153, 153, 1);
          }

          img {
            height: 0.2rem;
            margin-left: 0.14rem;
          }
        }
      }
    }
  }

  .group-content {
    margin-bottom: 0.2rem;
  }

  .evaluate-content {
    width: 6.9rem;
    margin: 0 auto;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.05);
    margin-bottom: 0.38rem;

    .evaluate-title {
      padding: 0.2rem 0.2rem 0;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .tip {
        font-size: 0.24rem;
        color: #666666;
      }

      .look-more {
        display: flex;
        align-items: center;
        font-size: 0.24rem;
        color: var(--main-color);

        img {
          height: 0.2rem;
          margin-left: 5px;
        }
      }
    }
  }

  .goods-html {
    .content-title {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 3.22rem;
      height: 0.54rem;
      border-radius: 0.54rem;
      background: rgba(108, 87, 255, 0.1);
      margin: 0 auto;
      padding: 0 0.25rem;
      box-sizing: border-box;

      .dian {
        width: 2px;
        height: 2px;
        background: #333333;
        border-radius: 2px;
      }

      .line {
        width: 0.56rem;
        height: 1px;
        background: #333333;
      }

      .text {
        font-size: 0.24rem;
        color: #333333;
      }
    }
  }

  .contents {
    // transform-origin: 0px 0px;
    // font-size: 28px;
    // line-height: 2;
    padding-bottom: 1rem;
    /deep/ div {
      width: 100% !important;
    }

    /deep/ p {
      width: 100% !important;
    }

    /deep/ img {
      width: 100%;
      margin-bottom: -3px;
    }

    /deep/ table {
      width: 100%;
    }

    /deep/ img:last-child {
      width: 100%;
      /*margin-bottom: 1.5rem;*/
    }
  }

  .jd {
    transform-origin: 0px 0px;
    // font-size: 28px;
    line-height: 0;
    padding-bottom: 2rem;
    width: 100%;

    /* /deep/ div {
      width: 100% !important;
    } */

    /deep/ img {
      display: inline-block;
      // max-width: none!important;
      max-width: 100%;
    }
  }
  .goods-bot {
    width: 100%;
    height: 1.1rem;
    background: #fff;
    position: fixed;
    z-index: 6;
    bottom: 0;
    display: flex;
    align-items: center;
    border-top: 1px solid #e1e1e1;

    .bot-icon {
      width: 0.6rem;
      margin-left: 0.3rem;
      text-align: center;

      img {
        width: 0.42rem;
        // height: 0.48rem;
        margin-bottom: 2px;
      }

      p {
        text-align: center;
        font-size: 0.2rem;
        line-height: 0.28rem;
        color: #333333;
        white-space: nowrap;
      }
    }
    .PopUp {
      // background-color: red;
      background-color: rgba(0, 0, 0, 0.5);
      position: absolute;
      width: 100%;

      bottom: 0%;
      height: 2000%;
      .bot-mobile {
        width: 80%;
        // border: 1px solid #fff;
        position: absolute;
        height: 3rem;
        bottom: 4rem;
        left: 0.6rem;
        text-align: center;
        line-height: 80px;
        background-color: #fff;
        margin-left: 0.3rem;
        border-radius: 10px;
        img {
          width: 0.5rem;
          height: 0.5rem;
          float: right;
          margin: 0.2rem;
        }
        .service-phone {
          font-size: 19px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-content: center;
          position: absolute;
          margin-top: 0.5rem;
        }
        .service-phone span {
          color: #000;
        }
        .servicepnone {
          display: inline-block;
          // line-height: 4rem;
          width: 2rem;
          height: 0.5rem;
          font-size: 16px;
          color: #000;
          margin-top: 0.25rem;
        }
        .dial {
          left: 0rem;
          width: 1.5rem;
        }
        .dial,
        .cancel {
          position: absolute !important;
          font-size: 15px;
          border-radius: 0 0 0 10px;
          line-height: 0.8rem !important;
          height: 0.8rem;
          width: 3rem;
          // border: 1px solid #ccc;
          line-height: 0.5rem;
          text-align: center;
          color: #fff;
          top: 2.2rem !important;
          background: var(--main-color);
        }
        .cancel {
          left: 50% !important;
          width: 50%;
          border-radius: 0 0 10px 0;
          background: #f1f1f1;
          color: var(--main-color);
        }
      }
    }

    /* .no-car {
			margin-left: 1rem !important;
		} */

    .buy-btn {
      height: 0.8rem;
      border-radius: 0.8rem;
      border: 1px solid var(--main-color);
      display: flex;
      margin-left: 0.24rem;
      overflow: hidden;
      box-sizing: border-box;

      .add-car,
      .buy-now {
        width: 2.2rem;
        height: 0.8rem;
        text-align: center;
        font-size: 0.32rem;
        line-height: 0.8rem;
      }

      .sale-none {
        width: 2.2rem;
        height: 0.8rem;
        text-align: center;
        font-size: 0.32rem;
        line-height: 0.8rem;
        background: #999999;
        color: #fff;
      }

      .add-car,
      .self-buy {
        color: var(--main-color);
      }

      .buy-now,
      .group-buy {
        color: #ffffff;
        background: var(--main-color);
      }

      .self-buy,
      .group-buy {
        width: 2.2rem;
        height: 0.8rem;
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        p:nth-child(1) {
          font-size: 0.24rem;
        }

        p:nth-child(2) {
          font-size: 0.26rem;
          line-height: 0.36rem;
        }
      }
    }

    .sale-kill-btn {
      margin-left: 2.2rem;
      border: none;
    }

    .no-goods {
      width: 4.8rem;
      height: 0.8rem;
      background: #999999;
      text-align: center;
      line-height: 0.8rem;
      font-size: 0.32rem;
      color: #fff;
      border: 1px solid #999999;
      box-sizing: border-box;
      border-radius: 0.8rem;
      margin-left: 0.36rem;
    }
  }

  .point-topic {
    background: var(--main-color);
    justify-content: center;
    color: #fff;
    height: 0.9rem;
  }

  .ft-weight {
    font-weight: bold;
  }
}
</style>
