var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.status
    ? _c("div", { staticClass: "stage-dialog" }, [
        _c("div", { staticClass: "stage-main" }, [
          _c("div", { staticClass: "stage-content" }, [
            _c("h3", { staticClass: "title" }, [_vm._v("分期详情")]),
            _c(
              "ul",
              { staticClass: "stage-list" },
              _vm._l(_vm.list, function (item) {
                return _c("li", { key: item.num }, [
                  _c("p", [
                    _c("span", { staticClass: "plans money" }, [
                      _vm._v(_vm._s(item.avg_price)),
                    ]),
                    _c("span", { staticClass: "plans" }, [_vm._v("元/")]),
                    _vm._v("\n\t\t\t\t\t\t月起\n\t\t\t\t\t"),
                  ]),
                  _c("p", { staticClass: "gray" }, [
                    _vm._v(_vm._s(item.num) + "期"),
                  ]),
                ])
              }),
              0
            ),
            _c("div", { staticClass: "close", on: { click: _vm.close } }, [
              _vm._v("关闭"),
            ]),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }