<template>
	<div class="stage-dialog" v-if="status">
		<div class="stage-main">
			<div class="stage-content">
				<h3 class="title">分期详情</h3>
				<ul class="stage-list">
					<li v-for="item in list" :key="item.num">
						<p>
							<span class="plans money">{{ item.avg_price }}</span>
							<span class="plans">元/</span>
							月起
						</p>
						<p class="gray">{{ item.num }}期</p>
					</li>
				</ul>
				<div class="close" @click="close">关闭</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'Stage',
	props: {
		list: {
			type: Array,
			default: () => []
		},
		status: {
			type: Boolean,
			default: false
		}
	},
	methods: {
		close() {
			this.$emit('closeStage');
		}
	}
}	
</script>

<style lang="less" scoped>
@import '~@/common/less/variable.less';
@import '~@/common/less/base.less';

.stage-dialog {
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.5);
	position: fixed;
	top: 0;
	left: 0;
	z-index: 999;
	padding-bottom: 0.98rem;
	box-sizing: border-box;
	
	.stage-main {
		width: 100%;
		position: absolute;
		bottom: 0;
		box-sizing: border-box;
	}
	
	.stage-content {
		width: 100%;
		background: #fff;
		padding: 0.3rem;
		box-sizing: border-box;
		border-radius: 10px 10px 0px 0px;
		z-index: 999;
		
		.title {
			width: 100%;
			text-align: center;
			color: #58b8d8;
			font-size: 0.4rem;
			margin-bottom: 0.5rem;
		}
		
		.stage-list {
			font-size: 0.28rem;
			
			li {
				margin-top: 0.2rem;
				background-color: #efefef;
				padding: 0.2rem;
				border-radius: 0.1rem;
				border: 1px solid #eceaeb;
				display: flex;
				justify-content: space-between;
				
				.plans {
					color: #fe5d1c;
				}
				.money {
					font-size: 0.35rem;
					font-weight: bolder;
				}
				.gray {
					color: #000100;
				}
			}
		}
		
		.close {
			border-radius: 0.5rem;
			background-color: #efefef;
			width: 3rem;
			height: 0.8rem;
			line-height: 0.8rem;
			text-align: center;
			margin: 2rem auto 0.5rem auto;
		}
	}
}
</style>
