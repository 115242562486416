var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "goods-detail" },
    [
      _c("banner", {
        staticStyle: { "z-index": "0" },
        attrs: { banners: _vm.banners },
        model: {
          value: _vm.detail.is_collect,
          callback: function ($$v) {
            _vm.$set(_vm.detail, "is_collect", $$v)
          },
          expression: "detail.is_collect",
        },
      }),
      _vm.goodsType == 1 || _vm.goodsType == 2
        ? _c("flash-sale", {
            attrs: {
              goodsType: _vm.goodsType,
              detail: _vm.detail,
              goodsPrice: _vm.goodsPrice,
              businessType: _vm.businessType,
            },
          })
        : _vm._e(),
      _c("div", { staticClass: "goods-info" }, [
        _c("div", { staticClass: "goods-intro" }, [
          _c("p", { staticClass: "goods-name ft-weight" }, [
            _vm._v(_vm._s(_vm.detail.item_name)),
          ]),
          _c("p", { staticClass: "goods-tip" }, [
            _vm._v(_vm._s(_vm.detail.item_desc)),
          ]),
          _vm.goodsType == 0 || _vm.goodsType == 3
            ? _c(
                "div",
                { staticClass: "goods-price" },
                [
                  _vm.goodsType == 3
                    ? _c("div", { staticClass: "group-title" }, [
                        _vm._v("拼团价："),
                      ])
                    : _vm._e(),
                  _vm.businessType == 2
                    ? [
                        _vm.price_status == 1
                          ? _c("p", { staticClass: "real-price" }, [
                              _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.priceShow(
                                        _vm.detail.display_type,
                                        _vm.detail.price,
                                        _vm.detail.max_integral_percentage
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.goodsPrice.del_price &&
                        _vm.mall_price_status == 1 &&
                        _vm.detail.display_type !== 2
                          ? _c("s", { staticClass: "s-price" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.priceShow(
                                      _vm.detail.display_type,
                                      _vm.goodsPrice.del_price
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                        _vm.tripartite_type === "VUE_APP_ZJG" &&
                        _vm.detail.display_type !== 1
                          ? _c("span", { staticClass: "zjg-max" }, [
                              _vm._v(
                                "\n            积分最高抵扣" +
                                  _vm._s(_vm.detail.max_integral_percentage) +
                                  "%\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    : [
                        _vm.price_status == 1
                          ? _c("p", { staticClass: "real-price" }, [
                              _c("span", [_vm._v("¥")]),
                              _c("span", [
                                _vm._v(_vm._s(_vm.goodsPrice.real_price)),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.goodsPrice.del_price && _vm.mall_price_status == 1
                          ? _c("s", { staticClass: "s-price" }, [
                              _vm._v("¥" + _vm._s(_vm.goodsPrice.del_price)),
                            ])
                          : _vm._e(),
                      ],
                ],
                2
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "goods-serve" },
          _vm._l(_vm.serveItems, function (item, index) {
            return _c("div", { key: index, staticClass: "serve-item" }, [
              _c("img", { attrs: { src: item.img, alt: "" } }),
              _c("span", [_vm._v(_vm._s(item.content))]),
            ])
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "goods-buy" },
          [
            _vm.itemsnStatus
              ? _c("div", [
                  _vm.detail.display_type === 3
                    ? _c("div", { staticClass: "buy-item stage" }, [
                        _c("p", { staticClass: "buy-title" }, [
                          _vm._v("支付方式"),
                        ]),
                        _c(
                          "p",
                          {
                            staticClass: "buy-content",
                            on: { click: _vm.showStage },
                          },
                          [
                            _c("span", { staticClass: "plans money" }, [
                              _vm._v(_vm._s(_vm.avg_price)),
                            ]),
                            _c("span", { staticClass: "plans" }, [
                              _vm._v("元/"),
                            ]),
                            _c("span", { staticClass: "gary" }, [
                              _vm._v("月起 "),
                            ]),
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.stage_num) +
                                "期\n            "
                            ),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/ic_arrow_right_gray@2x.png"),
                                alt: "",
                              },
                            }),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.getVirtualStatus(_vm.detail.source_type)
                    ? _c(
                        "div",
                        {
                          staticClass: "buy-item",
                          on: {
                            click: function ($event) {
                              return _vm.chooseAddress()
                            },
                          },
                        },
                        [
                          _c("p", { staticClass: "buy-title" }, [
                            _vm._v("送至"),
                          ]),
                          _c("p", { staticClass: "buy-content" }, [
                            _vm.activedAddress.area_string
                              ? _c("span", [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.activedAddress.area_string) +
                                      "\n            "
                                  ),
                                ])
                              : _c("span", [
                                  _vm._v(
                                    _vm._s(_vm.default_address.area_string)
                                  ),
                                ]),
                            _c("img", {
                              attrs: {
                                src: require("@/assets/ic_arrow_right_gray@2x.png"),
                                alt: "",
                              },
                            }),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.detail.active_name
                    ? _c("div", { staticClass: "buy-item" }, [
                        _c("p", { staticClass: "buy-title" }, [_vm._v("活动")]),
                        _c("p", { staticClass: "buy-content" }, [
                          _c("span", { staticClass: "active-tip" }, [
                            _vm._v(_vm._s(_vm.detail.active_name)),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.detail.is_own == 0 &&
                  _vm.getVirtualStatus(_vm.detail.source_type)
                    ? _c("div", { staticClass: "buy-item" }, [
                        _c("p", { staticClass: "buy-title" }, [_vm._v("库存")]),
                        _c("p", { staticClass: "buy-content" }, [
                          _vm.goodsInfo.stock_name == "有货"
                            ? _c("span", { staticClass: "have-goods" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.goodsInfo.stock_name) +
                                    "\n            "
                                ),
                              ])
                            : _c("span", { staticClass: "no-goods" }, [
                                _vm._v(_vm._s(_vm.goodsInfo.stock_name)),
                              ]),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.detail.source_type
                    ? _c("div", { staticClass: "buy-item" }, [
                        _c("p", { staticClass: "buy-title" }, [_vm._v("来源")]),
                        _c("p", { staticClass: "buy-content" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getSourceTypes(_vm.detail.source_type)
                              ) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.salesStatus()
                    ? _c("div", { staticClass: "buy-item" }, [
                        _c("p", { staticClass: "buy-title" }, [_vm._v("已售")]),
                        _c("p", { staticClass: "buy-content" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.getSales(
                                  _vm.detail.id,
                                  _vm.detail.cost_price
                                )
                                  ? _vm.getSales(
                                      _vm.detail.id,
                                      _vm.detail.cost_price
                                    )
                                  : ""
                              ) +
                              "\n          "
                          ),
                        ]),
                      ])
                    : _vm._e(),
                  _vm.detail.source_type != 2 &&
                  _vm.detail.source_type != 3 &&
                  _vm.getVirtualStatus(_vm.detail.source_type) &&
                  !_vm.customAccount("VUE_APP_CHAOWEI")
                    ? _c("div", { staticClass: "buy-item" }, [
                        _c("p", { staticClass: "buy-title" }, [_vm._v("运费")]),
                        _c("p", { staticClass: "buy-content" }, [
                          _vm._v(_vm._s(_vm.goodsInfo.postage)),
                        ]),
                      ])
                    : _vm._e(),
                ])
              : _c("div", [
                  _c(
                    "div",
                    {
                      staticClass: "buy-item",
                      staticStyle: {
                        "border-bottom": "1px solid #e1e1e1",
                        "padding-bottom": "0.2rem",
                        "margin-bottom": "0.2rem",
                      },
                    },
                    [
                      _c("p", { staticClass: "buy-title" }, [_vm._v("SKU码")]),
                      _c("p", { staticClass: "buy-content" }, [
                        _vm._v(_vm._s(_vm.detail.item_sn)),
                      ]),
                    ]
                  ),
                ]),
            _vm.basic_fee > 0 &&
            _vm.detail.source_type != 2 &&
            _vm.detail.source_type != 3 &&
            _vm.getVirtualStatus(_vm.detail.source_type)
              ? _c(
                  "div",
                  { staticClass: "buy-item" },
                  [
                    _c("p", { staticClass: "buy-title" }, [_vm._v("基础运费")]),
                    _vm.businessType == 2
                      ? [
                          _c("p", { staticClass: "buy-content" }, [
                            _vm._v(_vm._s(_vm.priceShow(1, _vm.basic_fee))),
                          ]),
                        ]
                      : [
                          _c("p", { staticClass: "buy-content" }, [
                            _vm._v("¥" + _vm._s(_vm.basic_fee)),
                          ]),
                        ],
                  ],
                  2
                )
              : _vm._e(),
            _vm.goodsType == 3 ? _c("group-play") : _vm._e(),
          ],
          1
        ),
      ]),
      _vm.goodsType == 3 && _vm.groupList.length > 0
        ? _c(
            "div",
            { staticClass: "group-content" },
            [
              _c("group-list", {
                attrs: {
                  moreGroup: true,
                  groupList: _vm.groupList,
                  group_buy_item_id: _vm.detail.group_buy_item_info.id,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm._m(0),
      _vm.imgLists.length > 0
        ? _c(
            "div",
            {
              directives: [
                {
                  name: "lazy-container",
                  rawName: "v-lazy-container",
                  value: _vm.lazyloadConf,
                  expression: "lazyloadConf",
                },
              ],
              key: "img-lists",
              class: [_vm.isnew ? "jd" : "contents"],
            },
            _vm._l(_vm.imgLists, function (item, index) {
              return _c("img", { key: index, attrs: { "data-src": item } })
            }),
            0
          )
        : _c("div", {
            key: "img-html",
            class: [_vm.isnew ? "jd" : "contents"],
            style: {
              transform: _vm.isnew ? `scale(${_vm.scale})` : `scale(1)`,
            },
            domProps: { innerHTML: _vm._s(_vm.item_txt.contents) },
          }),
      _vm.itemsnStatus && !_vm.pointsTopic && _vm.show_only !== 1
        ? _c("div", { staticClass: "goods-bot" }, [
            _vm.ServiceStatus == 1
              ? _c(
                  "div",
                  {
                    staticClass: "bot-icon",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openChat.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon_shangpin_kefu@2x.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("客服")]),
                  ]
                )
              : _vm._e(),
            _vm.ServiceStatus == 0
              ? _c(
                  "div",
                  {
                    staticClass: "bot-icon",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.openChat.apply(null, arguments)
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/icon_my_kefu@3x.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("客服")]),
                  ]
                )
              : _vm._e(),
            _vm.sermob == true && _vm.ServiceStatus == 0
              ? _c("div", { staticClass: "PopUp" }, [
                  _c(
                    "div",
                    { staticClass: "bot-mobile", on: { click: _vm.openChat } },
                    [
                      _c("img", {
                        attrs: { src: require("@/assets/ic_back_.png") },
                        on: {
                          click: function ($event) {
                            $event.stopPropagation()
                            return _vm.cancel.apply(null, arguments)
                          },
                        },
                      }),
                      _c("span", { staticClass: "service-phone" }, [
                        _vm._v("\n          客服电话:\n          "),
                        _c("span", [_vm._v(_vm._s(_vm.ServiceMobile))]),
                      ]),
                      _c("span", { staticClass: "dial" }, [
                        _c(
                          "a",
                          { attrs: { href: "tel:" + _vm.ServiceMobile } },
                          [_vm._v("确认呼叫")]
                        ),
                      ]),
                      _c(
                        "span",
                        {
                          staticClass: "cancel",
                          on: {
                            click: function ($event) {
                              $event.stopPropagation()
                              return _vm.cancel.apply(null, arguments)
                            },
                          },
                        },
                        [_vm._v("取消")]
                      ),
                    ]
                  ),
                ])
              : _vm._e(),
            _c(
              "div",
              {
                staticClass: "bot-icon",
                on: {
                  click: function ($event) {
                    return _vm.shoppingCar()
                  },
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/icon_shangpin_gouwuche@2x.png"),
                    alt: "",
                  },
                }),
                _c("p", [_vm._v("购物车")]),
              ]
            ),
            _vm.is_collect == 0
              ? _c(
                  "div",
                  {
                    staticClass: "bot-icon",
                    on: {
                      click: function ($event) {
                        return _vm.collectGoods()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/ic_shoucang.png"),
                        alt: "",
                      },
                    }),
                    _c("p", [_vm._v("收藏")]),
                  ]
                )
              : _c(
                  "div",
                  {
                    staticClass: "bot-icon",
                    on: {
                      click: function ($event) {
                        return _vm.collectGoods()
                      },
                    },
                  },
                  [
                    _c("img", {
                      attrs: { src: require("@/assets/ic_qxsc.png"), alt: "" },
                    }),
                    _c("p", [_vm._v("取消收藏")]),
                  ]
                ),
            _vm.goodsInfo.stock_name === "无货" && _vm.goodsType !== 1
              ? _c("div", { staticClass: "no-goods" }, [
                  _vm._v("\n      该地区暂时无货\n    "),
                ])
              : _vm.detail.stock == 0 && _vm.goodsType !== 1
              ? _c("div", { staticClass: "no-goods" }, [
                  _vm._v(
                    "\n      " + _vm._s(_vm.goodsType) + "该地区暂时无货\n    "
                  ),
                ])
              : _vm.detail.isuse !== 1
              ? _c("div", { staticClass: "no-goods" }, [
                  _vm._v("\n      该商品已下架\n    "),
                ])
              : _vm.goodsType == 0
              ? _c("div", { staticClass: "buy-btn" }, [
                  _vm.carStatus
                    ? _c(
                        "div",
                        {
                          staticClass: "add-car",
                          on: {
                            click: function ($event) {
                              return _vm.showDialog(0)
                            },
                          },
                        },
                        [_vm._v("\n        加入购物车\n      ")]
                      )
                    : _c(
                        "div",
                        {
                          staticClass: "add-car",
                          staticStyle: {
                            opacity: "0.6",
                            background: "#ccc",
                            color: "#fff",
                          },
                        },
                        [_vm._v("\n        加入购物车\n      ")]
                      ),
                  _c(
                    "div",
                    {
                      staticClass: "buy-now",
                      on: {
                        click: function ($event) {
                          return _vm.showDialog(1)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        立即" +
                          _vm._s(
                            _vm.tripartite("VUE_APP_TABEN") ||
                              _vm.customAccount("VUE_APP_CHAOWEI")
                              ? "兑换"
                              : "购买"
                          ) +
                          "\n      "
                      ),
                    ]
                  ),
                ])
              : _vm.goodsType == 3
              ? _c("div", { staticClass: "buy-btn" }, [
                  _c(
                    "div",
                    {
                      staticClass: "self-buy",
                      on: {
                        click: function ($event) {
                          return _vm.showDialog(2)
                        },
                      },
                    },
                    [
                      _vm.mall_price_status == 1
                        ? [
                            _vm.businessType == 2
                              ? [
                                  _c("p", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.priceShow(
                                            _vm.detail.display_type,
                                            _vm.goodsPrice.del_price
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _c("p", [_vm._v("单独购买")]),
                                ]
                              : [
                                  _c("p", [
                                    _vm._v(
                                      "¥ " + _vm._s(_vm.goodsPrice.del_price)
                                    ),
                                  ]),
                                  _c("p", [_vm._v("单独购买")]),
                                ],
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _c(
                    "div",
                    {
                      staticClass: "group-buy",
                      on: {
                        click: function ($event) {
                          return _vm.showDialog(3)
                        },
                      },
                    },
                    [
                      _vm.price_status == 1
                        ? [
                            _vm.businessType == 2
                              ? [
                                  _c("p", [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.priceShow(
                                            _vm.detail.display_type,
                                            _vm.goodsPrice.real_price
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detail.group_buy_item_info
                                          .people_limit
                                      ) + "人开团"
                                    ),
                                  ]),
                                ]
                              : [
                                  _c("p", [
                                    _vm._v(
                                      "¥ " + _vm._s(_vm.goodsPrice.real_price)
                                    ),
                                  ]),
                                  _c("p", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.detail.group_buy_item_info
                                          .people_limit
                                      ) + "人开团"
                                    ),
                                  ]),
                                ],
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                ])
              : _vm.goodsType == 1
              ? _c("div", { staticClass: "buy-btn sale-kill-btn" }, [
                  _vm.detail.limit_item_info.stock === 0
                    ? _c("div", { staticClass: "sale-none" }, [
                        _vm._v("\n        已抢光\n      "),
                      ])
                    : _c(
                        "div",
                        {
                          staticClass: "buy-now",
                          on: {
                            click: function ($event) {
                              return _vm.showKillDialog(1)
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n        立即" +
                              _vm._s(
                                _vm.tripartite("VUE_APP_TABEN") ||
                                  _vm.customAccount("VUE_APP_CHAOWEI")
                                  ? "兑换"
                                  : "购买"
                              ) +
                              "\n      "
                          ),
                        ]
                      ),
                ])
              : _vm.goodsType === 2
              ? _c("div", { staticClass: "buy-btn sale-kill-btn" }, [
                  _c("div", { staticClass: "sale-none" }, [
                    _vm._v("秒杀未开始"),
                  ]),
                ])
              : _vm._e(),
          ])
        : _vm._e(),
      _vm.pointsTopic
        ? _c(
            "div",
            {
              staticClass: "goods-bot",
              class: { "point-topic": _vm.pointsTopic },
              on: {
                click: function ($event) {
                  return _vm.showDialog(1)
                },
              },
            },
            [_vm._v("\n    立即兑换\n  ")]
          )
        : _vm._e(),
      _c("select-address", {
        ref: "address",
        attrs: { edit_date: _vm.edit_date },
        on: { confirmAddress: _vm.confirmAddress },
      }),
      _vm.loadding
        ? _c("sku", {
            ref: "sku",
            attrs: {
              buyType: _vm.buyType,
              skuList: _vm.skuList,
              skuInfo: _vm.skuInfo,
              goodsType: _vm.goodsType,
              goodsDetail: _vm.detail,
              limitNum: _vm.limitNum,
            },
            model: {
              value: _vm.dialog,
              callback: function ($$v) {
                _vm.dialog = $$v
              },
              expression: "dialog",
            },
          })
        : _vm._e(),
      _vm.loadding
        ? _c("stage", {
            attrs: { status: _vm.stageDialog, list: _vm.stageList },
            on: { closeStage: _vm.showStage },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "goods-html" }, [
      _c("div", { staticClass: "content-title" }, [
        _c("span", { staticClass: "dian" }),
        _c("span", { staticClass: "line" }),
        _c("span", { staticClass: "text" }, [_vm._v("商品详情")]),
        _c("span", { staticClass: "line" }),
        _c("span", { staticClass: "dian" }),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }