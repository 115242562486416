wx.config({
  //debug: true,
  debug: false,
  appId: appId,
  timestamp: timestamp,
  nonceStr: nonceStr,
  signature: signature,
  jsApiList: [
    'checkJsApi',
    'onMenuShareTimeline',//旧的接口，即将废弃
    'onMenuShareAppMessage',//旧的接口，即将废弃
    'updateAppMessageShareData',
    'updateTimelineShareData',
    'onMenuShareQQ',
    'onMenuShareWeibo',
    'chooseImage',
    'uploadImage',
    'previewImage',
    'openLocation',
    'chooseWXPay',
  ]
});

/*wx.ready(function () {
  // 1 判断当前版本是否支持指定 JS 接口，支持批量判断
  wx.checkJsApi({
    jsApiList: [
      'getNetworkType',
      'previewImage',
      'onMenuShareTimeline',
      'onMenuShareAppMessage',
      'updateAppMessageShareData',
      'updateTimelineShareData',
      'onMenuShareQQ',
      'chooseImage',
      'uploadImage',
      'onMenuShareWeibo',
      'chooseWXPay',
    ],
    success: function (res) {
      // console.log('support: ' + JSON.stringify(res));
    }
  });
  //console.log('title = ' + title + ', desc = ' + desc + ', link = ' + link + ', img = ' + img + ', appId = ' + appId + ', timestamp = ' + timestamp + ', nonceStr = ' + nonceStr + ', signature = ' + signature);
  var shareData = {
    title:window.localStorage.getItem("getShareTitle"),
    desc:"我发现了一个很棒的商城，分享给你说不定你也喜欢",
    link:window.localStorage.getItem("getHomePath"),
    imgUrl: window.localStorage.getItem("getShareImg"),
    trigger: function (res) {
      // alert('用户点击发送给朋友');
    },
    success: function (res) {
      // alert('已分享');
    },
    cancel: function (res) {
      // alert('已取消');
    },
    fail: function (res) {
      // alert('call fail: ' + JSON.stringify(res));
    }
  };
  wx.onMenuShareAppMessage(shareData);
  wx.onMenuShareTimeline(shareData);
  wx.updateAppMessageShareData(shareData);
  wx.updateTimelineShareData(shareData);
  wx.onMenuShareQQ(shareData);
  wx.onMenuShareWeibo(shareData);
});*/
wx.error(function (res) {
  //alert('error: ' + res.errMsg);
});
